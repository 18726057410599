import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import {AccountService} from "../_services/account.service";

@Injectable({
  providedIn: 'root'
})
export class LibraryAccessGuard implements CanActivate {

  constructor(private accountService: AccountService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const libraryId = parseInt(state.url.split('library/')[1], 10);
    if (isNaN(libraryId)) return of(false);
    return this.accountService.hasLibraryAccess(libraryId);
  }
}
